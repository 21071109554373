import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          This site is not ready yet! Please come back when I have finished :)
        </p>
      </header>
    </div>
  );
}

export default App;
